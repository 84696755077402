import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Link from "next/link";
import * as React from "react";
import { signIn } from "next-auth/react";
import { ErrorAlert } from "components/Alert/Error";
import { useRouter } from "next/router";
import { setUserId } from "services/analytics";
import { useTranslations } from "next-intl";

interface SignInFormType {
  email: string;
  password: string;
}
export const SignIn = () => {
  const t = useTranslations("SignIn");
  const router = useRouter();
  const { locale } = router;
  const [loading, setLoading] = React.useState(false);
  const [errors, setError] = React.useState<string>();
  const [formValues, setFormValues] = React.useState<SignInFormType>({
    email: "",
    password: "",
  });

  const handleChange = (event: any) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const { email, password } = formValues;
    try {
      const result: any = await signIn("credentials", {
        redirect: false,
        email,
        password,
      });
      if (result.status === 400 || result.status === 401) {
        throw new Error(t("paramsInvalid"));
      }

      setUserId(email);
      router.push("/admin", "/admin", { locale });
    } catch (e: any) {
      if (e.status === 500) {
        setError(t("somethingWentWrong"));
      }
      setLoading(false);
      setError(e.message);
      console.log(e);
    }
  };
  return (
    <>
      <Container maxW="md" py={{ base: "12", md: "24" }}>
        <Stack spacing="8">
          <Stack spacing="6" alignItems="center">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
                {t("heading")}
              </Heading>
              <Text color="muted">{t("subtitle")}</Text>
            </Stack>
          </Stack>
          <ErrorAlert errors={errors} mb={4} borderRadius="lg" />
          <Stack spacing="6" as="form" onSubmit={onSubmit}>
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">{t("email")}</FormLabel>
                <Input
                  onChange={handleChange}
                  id="email"
                  name="email"
                  placeholder={t("emailPlaceholder")}
                  type="email"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">{t("password")}</FormLabel>
                <Input
                  onChange={handleChange}
                  id="password"
                  name="password"
                  placeholder={t("passwordPlaceholder")}
                  type="password"
                />
              </FormControl>
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>{t("rememberMe")}</Checkbox>
              <Link href="/forgot-password" locale={locale}>
                <Button variant="link" colorScheme="brand" size="sm">
                  {t("forgotPassword")}
                </Button>
              </Link>
            </HStack>
            <Stack spacing="4">
              <Button
                type="submit"
                isLoading={loading}
                variant="primary"
                fontWeight="bold"
              >
                {t("signInBtn")}
              </Button>
            </Stack>
          </Stack>
          <HStack spacing="1" justify="center">
            <Text fontSize="sm" color="muted">
              {t("noAccount")}
            </Text>
            <Link href="/sign-up" locale={locale}>
              <Button variant="link" colorScheme="brand" size="sm">
                {t("signUpBtn")}
              </Button>
            </Link>
          </HStack>
        </Stack>
      </Container>
    </>
  );
};
