import { Box, Container } from "@chakra-ui/react";
import { SignIn } from "components/SignIn";
import { SiteNav } from "components/SiteNav";
import pick from "lodash.pick";
import type { NextPage } from "next";
import Head from "next/head";
import { useIsLoggedIn } from "services/auth/react";

const LoginPage: NextPage = () => {
  useIsLoggedIn("/admin");
  return (
    <Box height="100vh">
      <Head>
        <title>Silkscan</title>
        <meta name="description" content="Silkscan" />
        <link rel="icon" href="/brand_icon.png" />
      </Head>

      <main>
        <SiteNav />
        <Container
          maxW="container.xl"
          py={{ base: "4", md: "8" }}
          px={{ base: "0", md: 8 }}
        >
          <SignIn />
        </Container>
      </main>
    </Box>
  );
};
const namespaces = ["SiteNav", "Footer", "CountdownTimer", "SignIn"];
export const getStaticProps = async (context: any) => {
  return {
    props: {
      messages: pick(
        (await import(`public/locales/${context.locale}/translation.json`))
          .default,
        namespaces
      ),
    },
  };
};

export default LoginPage;
