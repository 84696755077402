import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useIsLoggedIn = (onSuccessUrl?: string) => {
  const router = useRouter();
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      if (router.pathname === "/login") return;
      if (router.pathname === "/sign-up") return;

      router.push("/login");
    },
  });

  useEffect(() => {
    if (status === "authenticated" && onSuccessUrl) {
      console.log("authenticated");
      router.push(onSuccessUrl);
    }
  }, [status, onSuccessUrl, router]);

  return { status, session };
};
