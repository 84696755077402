import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  CloseButton,
} from "@chakra-ui/react";

/* Show error message */
export const ErrorAlert = ({ errors, ...styleProps }: any) => {
  if (!errors) return null;

  return (
    <Alert {...styleProps} status="error">
      <AlertIcon />
      {errors ||
        "There was an error processing your request. Please try again."}
    </Alert>
  );
};

/* Show default error message */
export const DefaultErrorAlert = ({ action }: any) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle mr={2}>Oops, we were unable to load your data.</AlertTitle>
      <AlertDescription marginRight={3}>
        Please try reloading the page.
      </AlertDescription>
      <Button size="sm" colorScheme="red" onClick={action}>
        Reload
      </Button>
      <CloseButton position="absolute" right="8px" top="8px" />
    </Alert>
  );
};
